/* global page */
import React, { Component } from 'react'

import classNames from 'classnames'

class Toolbar extends Component {
  render () {
    const { setSort = () => null, search, searchValue, setPage, page } = this.props
    const categories = {
      '*': {name: 'All', icon: 'globe'},
      'furniture': {name: 'Furniture', icon: 'bed'},
      'food': {name: 'Food', icon: 'cutlery'},
      'weapons': {name: 'Weapons', icon: 'bomb'},
      'animals': {name: 'Animals', icon: 'paw'},
      'decor': {name: 'Decor', icon: 'laptop'},
      'other': {name: 'Other', icon: 'fort-awesome'}
    }
    const isTopPage = ['top', 'recent'].includes(page)
    return (
      <div>
        {isTopPage ? this.props.announcements.map(function (announcement) {
          return (
            <div className={'alert alert-dismissible alert-' + announcement.type} key={announcement.id}>
              <button type='button' className='close' data-dismiss='alert'><i className='fa fa-times' />
              </button>
              <h4>Announcement!</h4>
              <p dangerouslySetInnerHTML={{__html: announcement.announcement}} />
            </div>
          )
        }) : <div />}
        {isTopPage ? <div className='btn-group btn-group-justified' style={{marginBottom: 20}} role='group'>
          <div
            className={classNames('btn btn-primary', { active: page === 'recent' })}
            onClick={setPage.bind(this, 'recent')}>Recent Models</div>
          <div
            className={classNames('btn btn-primary', { active: page === 'top' })}
            onClick={setPage.bind(this, 'top')}>Top Rated Models</div>
        </div> : null}
        <div className='hidden-xs sortToolbar'>
          <div className='btn-toolbar' style={{marginBottom: 20}} data-toggle='buttons'>
            <div className='btn-group' role='group'>
              <label className='btn btn-info'>Filter By: </label>
              {
                Object.keys(categories).map((key) => {
                  const obj = categories[key]
                  return (
                    <label className={classNames('btn btn-primary main-ops', {
                      'active': key === '*' // this is handled with the css itself, so set to all
                    })} onClick={setSort.bind(null, key)} key={key}>
                      <input type='radio' name={obj.name} defaultValue={key} defaultChecked /><i className={`fa fa-${obj.icon}`} /> {obj.name}
                    </label>
                  )
                })
              }
            </div>
            <div className='input-group pull-right col-sm-2 col-md-3 col-lg-3'>
              <input type='text' className='form-control' placeholder='Search' onChange={search} value={searchValue} />
              <div className='input-group-btn'>
                <button className='btn btn-muted' type='button'><i className='fa fa-search' />
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className='visible-xs sortToolbar'>
          <div className='btn-group btn-group-justified' role='group' style={{marginBottom: 20}}>
            <div className='btn-group' role='group'>
              <button type='button' className='btn btn-default dropdown-toggle' data-toggle='dropdown' aria-haspopup='true' aria-expanded='false'>
                Filter By{' '}
                <span className='caret' style={{marginBotton: 20}} />
              </button>
              <ul className='dropdown-menu' role='menu' style={{width: '100%'}}>
                {
                  Object.keys(categories).map((key) => {
                    const obj = categories[key]
                    return (
                      <li onClick={setSort.bind(null, key)}><a><i className={`fa fa-${obj.icon}`} key={key} /> {obj.name}</a></li>
                    )
                  })
                }
              </ul>
            </div>
          </div>
          <div className='input-group col-sm-2' style={{marginBottom: 10}}>
            <input type='text' className='form-control' placeholder='Search' onChange={search} value={searchValue} />
            <div className='input-group-btn'>
              <button className='btn btn-muted' type='button'><i className='fa fa-search' />
              </button>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Toolbar
