/* global $ */
import React, { Component } from 'react'

import classNames from 'classnames'

class Card extends Component {
  constructor (props) {
    super(props)
    this.state = {
      diamonds: Number(props.diamonds),
      lastDiamondState: props.lastDiamondState
    }
    this.toggleDiamonds = this.toggleDiamonds.bind(this)
  }

  toggleDiamonds () {
    const { lastDiamondState } = this.state
    const { loggedin } = this.props
    if (loggedin) {
      if (lastDiamondState === 'down') {
        this.setState({lastDiamondState: 'up'})
        this.setState({diamonds: this.state.diamonds + 1})
        fetch('/api/model/diamond/' + this.props.number)
      } else {
        this.setState({lastDiamondState: 'down'})
        this.setState({diamonds: this.state.diamonds - 1})
        fetch('/api/model/diamond/' + this.props.number)
      }
    }
  }

  stripHtmlCode (string) {
    return string.toString().replace('<code>', '').replace('</code>', '')
  }

  render () {
    const { lastDiamondState, diamonds } = this.state
    const { name, number, category, short, imageLink, version, username, rank, certified, loggedin } = this.props
    return (
      <div className={'item col-sm-6 col-md-4 ' + category}>
        <div className='thumbnail'>
          <a href={'/model/' + this.stripHtmlCode(number) + '/'}>
            <img src={imageLink} alt={name} className='img-responsive' style={{width: '100%', height: 250}} />
          </a>
          <div className='caption'>
            <a href={'/model/' + this.stripHtmlCode(number) + '/'} className='text-primary'>
              <h3 style={{fontSize: '20px'}} dangerouslySetInnerHTML={{__html: name}} />
            </a>
            <span className='label label-warning'>
              Minecraft <span dangerouslySetInnerHTML={{__html: version}} />
            </span>
            <span className='label label-default' style={{marginLeft: '3px'}}>
              {`${this.props.commentCount} ${this.props.commentCount === 0 || this.props.commentCount > 1 ? 'Comments' : 'Comment'}`}
            </span>
            <h3 style={{paddingTop: '5px', marginTop: '5px'}}>
              <small>
                <p className='text-default'>{category[0].toUpperCase() + category.slice(1)}</p>
              </small>
            </h3>
            <p dangerouslySetInnerHTML={{__html: short}} style={{lineHeight: '2.5ex', height: '7.5ex', overflow: 'hidden'}} />
            <div className='btn-toolbar'>
              <div className='btn-group' role='group'>
                <a onClick={(loggedin && this.toggleDiamonds) || null} className={classNames('btn diamondbutton', {
                  'btn-success': lastDiamondState === 'down',
                  'btn-danger': lastDiamondState === 'up',
                  'disabled': !loggedin
                })} role='button' data-id={this.props.number}>
                  <i className='fa fa-plus' /> <i className='fa fa-diamond' />
                </a>
                <a className='btn btn-info disabled diamondsnum' role='button' data-nid={this.props.number}>{diamonds}</a>
              </div>
              <a href={'/user/' + this.stripHtmlCode(username) + '/'} target='_blank' className={classNames('btn pull-right', {
                'btn-danger': rank === 'ADMINISTRATOR',
                'btn-success': rank === 'MODERATOR',
                'btn-warning': rank === 'PATREON',
                'btn-primary': rank === 'USER'
              })}>
                <span dangerouslySetInnerHTML={{__html: this.props.username}} />
                {' '}{certified && <i className='fa fa-check-square' data-toggle='tooltip' title='Certified User' />}
              </a>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Card
