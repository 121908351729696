/* global $ */
import React, { version } from 'react'
import { render } from 'react-dom'

import List from './List'

import 'whatwg-fetch'

console.log('React.js version ' + version)
render(<List />, document.getElementById('app-mount'))
