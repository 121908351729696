/* global loggedin, $ */
import React, { Component } from 'react'
import Masonry from 'react-masonry-component'

import Toolbar from './Toolbar'
import Card from './Card'
import NoneFound from './NoneFound'
import Loading from './Loading'

function chunk (arr, chunkSize) {
  let R = []
  for (let i = 0, len = arr.length; i < len; i += chunkSize) { R.push(arr.slice(i, i + chunkSize)) }
  return R
}

class List extends Component {
  constructor (props) {
    super(props)
    this.state = {
      page: 1,
      sort: '*',
      search: '',
      modelsPage: window.page || props.page || 'top',
      modelsUnsorted: null,
      announcements: []
    }
    this.setSort = this.setSort.bind(this)
    this.setSearch = this.setSearch.bind(this)
    this.updatePage = this.updatePage.bind(this)
    this.setPage = this.setPage.bind(this)
  }

  componentDidMount () {
    $('[data-toggle="tooltip"]').tooltip()
    this.getModelsPageData()
    this.getAnnouncements()
  }

  componentDidUpdate () {
    $('[data-toggle="tooltip"]').tooltip()
  }

  setSort (type) {
    this.setState({sort: type, page: 1})
  }

  setSearch (event) {
    this.setState({search: event.target.value})
  }

  setPage (page) {
    this.setState({ modelsPage: page })
    this.getModelsPageData(page)
  }

  updatePage (val) {
    this.setState({page: val})
    window.scrollTo(0, 0)
  }

  async getModelsPageData (page) {
    const { modelsPage } = this.state
    this.setState({ modelsUnsorted: null })
    const data = await (await fetch('https://blockmodels.com/api/model/models/' + (page || modelsPage))).json()
    this.setState({ modelsUnsorted: data })
  }

  async getAnnouncements () {
    const announcements = await (await fetch('https://blockmodels.com/api/admin/announcements')).json()
    this.setState({ announcements })
  }

  render () {
    const { search: searchTerm, modelsUnsorted, announcements, modelsPage } = this.state
    const masonryOptions = {
      itemSelector: '.item',
      transitionDuration: '0.2s'
    }

    const newModels = (modelsUnsorted || []).map((thisModel) => {
      thisModel = JSON.parse(JSON.stringify(thisModel))

      if (searchTerm !== '') {
        const search = searchTerm.toLowerCase()
        const placesToCheck = ['short', 'category', 'mcVersion', 'creator.name', 'title']
        // console.log(thisModel.short.toLowerCase().includes(search),thisModel.category.toLowerCase().includes(search),thisModel.mcVersion.toLowerCase().includes(search),thisModel.creator.name.toLowerCase().includes(search),thisModel.title.toLowerCase().includes(search))
        if (
          thisModel.short.toLowerCase().includes(search) ||
          thisModel.category.toLowerCase().includes(search) ||
          thisModel.mcVersion.toLowerCase().includes(search) ||
          thisModel.creator.name.toLowerCase().includes(search) ||
          thisModel.title.toLowerCase().includes(search)
        ) {
          const re = new RegExp('(' + search + ')', 'ig')
          for (let item of placesToCheck) {
            let place
            if (item === 'creator.name') {
              place = thisModel['creator']['name']
            } else {
              place = thisModel[item]
            }
            if (place.match(re)) {
              if (item === 'creator.name') {
                thisModel['creator']['name'] = thisModel['creator']['name'].replace(re, '<code>$1</code>')
              } else {
                thisModel[item] = thisModel[item].replace(re, '<code>$1</code>')
              }
            }
          }
          return thisModel
        }
      } else {
        if (this.state.sort === '*' || this.state.sort === thisModel.category) {
          return thisModel
        }
      }
    }).filter((item) => item !== undefined)

    const models = chunk(newModels, 9)

    const page = this.state.page - 1

    const modelPage = models.length !== 0 ? models[page] : []

    return (
      <div>
        <Toolbar
          announcements={announcements}
          models={modelPage}
          setSort={this.setSort}
          search={this.setSearch}
          setPage={this.setPage}
          searchValue={searchTerm}
          page={modelsPage} />
        <Masonry className='row main-row' updateOnEachImageLoad options={masonryOptions}>
          {
            modelsUnsorted === null
              ? <Loading />
              : modelPage && modelPage.length
                ? modelPage.map((model) => {
                  return model ? (
                    <Card
                      key={model.id}
                      loggedin={loggedin}
                      certified={model.creator.certified}
                      rank={model.creator.rank}
                      username={model.creator.name}
                      name={model.title}
                      category={model.category}
                      version={model.mcVersion}
                      number={model.id}
                      imageLink={model.image}
                      diamonds={model.diamonds}
                      lastDiamondState={model.voted ? 'up' : 'down'}
                      short={model.short}
                      commentCount={model.commentcount} />
                  ) : <NoneFound />
                })
                : <NoneFound />
          }
        </Masonry>
        {models.length > 1 ? <div className='text-center'>
          <ul className='pagination'>
            {
              models.map((model, index) => {
                return (
                  <li className={page === index ? 'active' : ''} key={index}>
                    <a style={{cursor: 'pointer'}} onClick={this.updatePage.bind(null, index + 1)}>{index + 1}</a>
                  </li>
                )
              })
            }
          </ul>
        </div> : null}
      </div>
    )
  }
}

export default List
