import React from 'react'

const Loading = () => (
  <h1 className='text-center'>
    <i className='fa fa-spin fa-cog' />
    {' '}
    Loading...
  </h1>
)

export default Loading
